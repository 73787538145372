<template>
  <div class="content-right rounded-3">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="{ name: 'dashboard' }">Dashboard</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link :to="{ name: 'AnnualLeaves' }"
            >Annual Leaves</router-link
          >
        </li>
        <li class="breadcrumb-item active">Edit</li>
      </ol>
    </nav>
    <div
      class="content-body"
      id="fullHeight"
      style="min-height: calc(100vh - 135px)"
    >
      <div class="row align-items-center">
        <div class="col-5">
          <h2 class="title2">Update Annual Leaves</h2>
        </div>
        <div class="col text-end">
          <router-link
            :to="{ name: 'AnnualLeaves' }"
            class="btn btn-outline-primary"
            >Back</router-link
          >
        </div>
      </div>
      <form @submit.prevent="onSubmit">
        <div class="row">
          <div class="col-lg-6 mb-3">
            <div class="card">
              <div class="card-header"></div>
              <div class="card-body">
                <div class="mb-3">
                  <label class="form-label">Edit Annual Leaves</label>
                  <div>
                    <input
                      type="text"
                      class="form-control"
                      v-model="$v.form.grantedleaves.$model"
                      :validator="$v.form.grantedleaves"
                    />
                  </div>
                  <p class="error" v-if="$v.form.grantedleaves.$error == true">
                    Annaul Leaves Field is required
                  </p>
                </div>
                <div class="text-right">
                  <button type="submit" class="btn btn-outline-primary space">
                    Save
                  </button>
                  <router-link
                    :to="{ name: 'AnnualLeaves' }"
                    class="btn btn-outline-primary"
                    >Cancel</router-link
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";
export default {
  data() {
    return {
      form: {
        grantedleaves: null,
      },
    };
  },
  validations: {
    form: {
      grantedleaves: {
        required,
      },
    },
  },
  methods: {
    onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
    },
  },
};
</script>
